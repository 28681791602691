<template>
  <div>
    <div class="box">
      <div class="b_div_can" v-for="(item,idx) in detailList" :key="idx">
        <div class="b_div_son_can">
          <div class="haad-title">{{ item.name }}</div>
          <el-row :span="24" style="margin-top: 15px">
            <el-col :span="13" class="right-center-box">
              <el-col :span="10" class="center-box-img"><img src="../../assets/index/status1.png" alt="" width="36"
                                                             height="36"></el-col>
              <el-col :span="14" style="text-align: left;">
                <el-col class="center-box-size-title">空气温度</el-col>
                <el-col class="center-box-size-con">{{ item.data ? item.data.temper :'--' }}℃</el-col>
              </el-col>
            </el-col>

            <el-col :span="11" class="right-center-box">
              <el-col :span="10" class="center-box-img" ><img src="../../assets/index/status2.png" alt="" width="33"
                                                             height="36"></el-col>
              <el-col :span="14" style="text-align: left;">
                <el-col class="center-box-size-title">土壤湿度</el-col>
                <el-col class="center-box-size-con">{{ item.data ? item.data.landHumid :'--' }}%</el-col>
              </el-col>
            </el-col>

            <el-col :span="13" class="right-center-box">
              <el-col :span="10" class="center-box-img"  style="margin-top: -5px"><img src="../../assets/index/status3.png" alt="" width="36"
                                                             height="26"></el-col>
              <el-col :span="14" style="text-align: left;">
                <el-col class="center-box-size-title">二氧化碳浓度</el-col>
                <el-col class="center-box-size-con">{{ item.data ? item.data.co2 :'--' }}ppm</el-col>
              </el-col>
            </el-col>

            <el-col :span="11" class="right-center-box">
              <el-col :span="10" class="center-box-img"><img src="../../assets/index/status4.png" alt="" width="36"
                                                             height="37"></el-col>
              <el-col :span="14" style="text-align: left;">
                <el-col class="center-box-size-title">光照度</el-col>
                <el-col class="center-box-size-con">{{ item.data ? item.data.light :'--' }}klux</el-col>
              </el-col>
            </el-col>
          </el-row>
          <div id="canopyManagement" >
            <el-button    @click="toDeatil(item.id)">管理</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CanopyManagementList",
  data() {
    return {
      detailList: {}
    }
  },
  mounted() {
    console.log(this.$store.state.user, '卡那看那那那')

    this.$api.post('gHouse/getByBid', {}, res => {
      console.log(res)
      this.detailList = res.data
    })
  },
  methods: {
    toDeatil(data) {
      console.log(data,'路由传参')
      if(this.$getPermission('/pc/data/getListEqui')){
        this.$router.push({
          name: "CanopyManagement",
          query: {
            id: data
          }
        })
      }else {
        this.$message({
          showClose: true,
          message: '暂无查看权限',
          type: 'warning'
        });
      }

    }
  }
}
</script>

<style>

.box {
  width: 1200px;
  margin: 20px auto;
  /*border: 1px solid #ccc;*/
}

.b_div_can {
  width: 290px;
  height: 272px;
  padding: 5px;
  float: left;
}

.b_div_son_can {
  width: 290px;
  height: 272px;
  /*border: 1px solid #333;*/
  background: #FFFFFF;
  text-align: center;
}

.haad-title {
  height: 59px;
  line-height: 59px;
  font-size: 20px;
  color: #009400;
  border-bottom: 1px solid #E8E8E8;
}

.center-box-img {
  line-height: 68px;
  text-align: center
}

.center-box-size-title {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;

}

.center-box-size-con {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #0090FF;

}

#canopyManagement .el-button {
  width: 250px;
  height: 40px;
  background: #009201;
  border-radius: 20px;
  color: #FFFFFF;
  margin-top: 10px;
}
</style>
